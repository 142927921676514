import Button from '@/components/common/Button';
import { OUTER_URLS } from '@/constants/common';
import { GA4_SCREEN_NAME } from '@/constants/ga4CustomEvent';
import { signInUrl } from '@/helpers/url.helper';
import useGAEvent from '@/hooks/useGAEvent';
import { useAuthStore } from '@/recoil/authStore/useAuthStore';
import { TicketRepository } from '@/repositories/TicketRepository';
import Head from 'next/head';
import { useEffect, useState } from 'react';

import TicketsSvg from '@/assets/images/tickets.svg';
import { useWeb3Auth } from '@/services/web3Auth/Web3Auth';
// import dynamic from 'next/dynamic';

// const WalletConnectButton = dynamic(() => import('@/components/common/WalletConnectButton'), {
//   ssr: false,
// });

const LoginPage = () => {
  useGAEvent(GA4_SCREEN_NAME.LOGIN_SCREEN);

  const { web3AuthLogout, connectWeb3AuthAndSignInWithEthereum } = useWeb3Auth();
  const { isAuthenticated } = useAuthStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmitLogout = () => {
    setIsLoading(true);
    web3AuthLogout(signInUrl());
  };

  const handleSubmitConnectWeb3 = () => {
    setIsLoading(true);
    connectWeb3AuthAndSignInWithEthereum().finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    (async () => {
      await TicketRepository.removeFromBrowser();
    })();
  }, []);

  return (
    <>
      <Head>
        <title>アスティーダ招待 チケット for web</title>
      </Head>
      <div className="flex flex-col justify-center font-noto gap-9">
        <p className="text-primaryRed text-[21px] leading-[31px] font-bold text-center whitespace-pre-line">
          {'アスティーダ招待チケット \nfor web'}
        </p>
        <div className="flex flex-col items-center text-center gap-9">
          <TicketsSvg />
          <div className="flex flex-col gap-9 text-sm leading-[21px] text-black1 font-normal text-left w-full">
            <p>
              高速で繰り広げられる熱き
              <br />
              卓球を観戦しよう！
              <br />
              このサービスでは無料チケット表示機能ほか
              <br />
              卓球観戦を楽しくする機能を提供予定！
            </p>
            <p className="font-bold">ログインしてチケットを表示できます</p>
          </div>
          <div className="w-full flex flex-col gap-4">
            <div className="w-full flex flex-col gap-4">
              {isAuthenticated ? (
                <Button type="submit" title="ログアウト" isLoading={isLoading} onClick={handleSubmitLogout} />
              ) : (
                <>
                  <Button type="submit" title="ログイン" isLoading={isLoading} onClick={handleSubmitConnectWeb3} />
                  {/* <WalletConnectButton title="Web3ウォレットでログインする方はコチラ" /> */}
                </>
              )}
            </div>
            <div className="text-center text-primaryRed font-bold text-sm leading-[21px]">
              <a href={OUTER_URLS.HOW_TO_USE} className="underline" target="_blank" rel="noreferrer">
                使い方
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
